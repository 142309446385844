import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { RoleRight } from '@dep/common/interfaces';
import { UserStatus } from '@dep/common/user-api/types/user.type';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, take, takeWhile } from 'rxjs';

import { UserTokenService } from './user-token.service';

import { UserService } from '@dep/frontend/services/user.service';

@Injectable()
export class AuthGuard {
  constructor(
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly userTokenService: UserTokenService,
  ) { }

  /**
   * Guard method that decides if children can be loaded.
   * If all guards return `true`, navigation continues. If any guard returns `false`,
   * navigation is cancelled.
   *
   * Parameters `route: Route` and `segments: UrlSegment[]` are not used for the check currently.
   */
  public async canLoad(): Promise<boolean> {
    return this.check(this.router.routerState.snapshot.root);
  }

  /**
   * Guard method that decides if a route can be activated.
   * If all guards return `true`, navigation continues. If any guard returns `false`,
   * navigation is cancelled.
   *
   * Second parameter `state: RouterStateSnapshot` is not used for the check currently.
   *
   * @param route
   */
  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.check(route);
  }

  private async check(route: ActivatedRouteSnapshot): Promise<boolean> {
    // check if the user is logged in
    this.logger.debug('Auth guard checks login', route);

    // Loading indicator for this is handled in `AppComponent` (`GuardsCheckStart`/`GuardsCheckEnd` events).
    const currentUser = await Auth.currentAuthenticatedUser()
      .catch((error: any) => { this.logger.warn('AuthGuard: currentAuthenticatedUser', error); }) as CognitoUser | null;
    this.logger.debug('Auth guard checks login of user', currentUser);

    if (!currentUser) {
      this.logger.debug('Auth guard denied access. Reason: Not logged in. Redirecting to login.');

      // Append redirect URL which will be used after successful login.
      // Do not append empty URLs or the login URL itself.
      const redirectToUrl = new URL(window.location.href);
      const redirectTo = ['', '/', '/login'].includes(redirectToUrl.pathname) ? undefined : redirectToUrl.href;

      // Only redirect if the user is not already on the login page with another login error set.
      const currentQueryParams = await lastValueFrom(this.router.routerState.root.queryParams.pipe(take(1)));
      if (!currentQueryParams.login_error) {
        this.router.navigate(['login'], { queryParams: { redirectTo, login_error: 'NOT_IS_LOGGED_IN' } });
      }
      return false;
    }

    // If sign-in process is still running, wait until it is done.
    if (this.userService.isSignInProcessRunning.value === true) {
      this.logger.debug('Auth guard waits until sign-in process is done');
      await lastValueFrom(this.userService.isSignInProcessRunning.pipe(takeWhile((isRunning) => isRunning === true)));
      this.logger.debug('Auth guard received that sign-in process is done');
    }

    const payload = (await this.userTokenService.getCognitoTokens()).idToken?.decodePayload();
    this.logger.debug('Auth guard checks user payload', payload);
    if (payload && payload['custom:status'] !== UserStatus.Active) {
      this.logger.warn('User not ACTIVE, denying route access');
      this.router.navigate(['login'], { queryParams: { login_error: 'STATUS_NOT_ACTIVE' } });
      return false;
    }

    // return true if no rights are required for access to this site
    this.logger.debug('Auth guard checks rights with route config:', route.routeConfig);
    if (
      typeof route.routeConfig?.data === 'undefined'
      || (route.routeConfig.data.rights && typeof route.routeConfig.data.rights === 'undefined')
      || (route.routeConfig.data.rights && !Array.isArray(route.routeConfig.data.rights))
      || (route.routeConfig.data.rights && !route.routeConfig.data.rights.length)
      || (route.routeConfig.data.rightsOr && typeof route.routeConfig.data.rightsOr === 'undefined')
      || (route.routeConfig.data.rightsOr && !Array.isArray(route.routeConfig.data.rightsOr))
      || (route.routeConfig.data.rightsOr && !route.routeConfig.data.rightsOr.length)
    ) {
      this.logger.debug('Auth guard granted access. Reason: required rights empty.');
      return true;
    }

    // check if the user has all required rights
    if (route.routeConfig.data.rights) {
      if (!await this.userService.hasRightAnd(route.routeConfig.data.rights as Array<RoleRight>)) {
        this.logger.debug('Auth guard denied access. Reason: Not meeting required rights. Reroute to home page.');
        this.router.navigate(['/home']);
        return false;
      }
    }
    if (route.routeConfig.data.rightsOr) {
      if (!await this.userService.hasRightOr(route.routeConfig.data.rightsOr as Array<RoleRight>)) {
        this.logger.debug('Auth guard denied access. Reason: Not meeting required rights. Reroute to home page.');
        this.router.navigate(['/home']);
        return false;
      }
    }

    return true;
  }
}
